// for contact Page tab

// gsap
//   .timeline()
//   .from(
//     ".location-wrapper",
//     1,
//     {
//       y: 200,
//       ease: Power3.easeOut,
//         // autoAlpha: 0,

//     scrollTrigger: {
//       trigger: ".location-wrapper",
//       start: "top 70%",
//       end: "bottom 25%",
//       scrub: 1,
//       // toggleActions:"restart pause reverse reset",
//       // markers:true
//     },

//     },
//     0
//   );

$("nav a").click(function (e) {
  e.preventDefault();
  $("nav a").removeClass("active");
  $(this).addClass("active");
  if (this.id === !"usa") {
    $(".usa").addClass("noshow");
  } else if (this.id === "usa") {
    $(".usa").removeClass("noshow");
    $(".rightbox").children().not(".usa").addClass("noshow");
  } else if (this.id === "nepal") {
    $(".nepal").removeClass("noshow");
    $(".rightbox").children().not(".nepal").addClass("noshow");
  }
});

// End of contact page tab

// sidebar
var estado = 0,
  vw = $(window).width();
$("#hamburger").click(function () {
  var delay_time = 0;
  $(this).toggleClass("open fix-hamburger");
  if (estado === 0) {
    gsap.to($("#bg-menu-mobile"), 1, {
      x: -vw,
      ease: Expo.easeInOut,
    });

    $("li").each(function () {
      gsap.to($(this), 1.2, {
        x: -vw,
        scaleX: 1,
        delay: delay_time,
        ease: Expo.easeInOut,
      });
      delay_time += 0.04;
    });

    estado = 1;
  } else {
    estado = 0;
    gsap.to($("#bg-menu-mobile"), 1.2, {
      x: 0,

      ease: Expo.easeInOut,
    });
    $("li").each(function () {
      gsap.to($(this), 1, {
        x: 0,
        delay: delay_time,
        ease: Expo.easeInOut,
      });
      delay_time += 0.02;
    });
  }
});

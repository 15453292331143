(function () {
  gsap.config({
    nullTargetWarn: false,
  });

  gsap.registerPlugin(ScrollTrigger);

  let timeline1 = gsap.timeline();

  //   gsap
  //     .timeline()
  //     .staggerFrom(
  //       ".expertise-wrapper",
  //       1,
  //       { y: 200, autoAlpha: 0, ease: Power3.easeOut },
  //       0.5
  //     );

  gsap
    .timeline({
      scrollTrigger: {
        trigger: ".title-section",
        // start: "top 70%",
        // end: "bottom 25%",
        scrub: true,
      },
    })

    .from(
      ".title-section",
      1,
      {
        y: -20,
        autoAlpha: 0,
        ease: Power3.easeOut,
        // delay: 1.5,
      },
      1
    )
    .from(
      "#Layer_1",
      3,
      {
        x: "-100%",
        autoAlpha: 0,
        ease: Power3.easeOut,
        // delay: 1.5,
      },
      1
    )
    // .staggerFrom(
    //   [".work-icon-1",".work-icon-2",".work-icon-3",".work-icon-4"],
    //   1,
    //   {
    //     y: 200,
    //     autoAlpha: 0,
    //     ease: Power3.easeOut,
    //     delay: 1,
    //   },
    //   1.4
    // )
    .from(
      ".work-icon-1",
      1,
      {
        y: 200,
        autoAlpha: 0,
        ease: Power3.easeOut,
        delay: 1,
      },
      1.4
    )
    .from(
      ".work-icon-2",
      1,
      {
        y: 200,
        autoAlpha: 0,
        ease: Power3.easeOut,
        delay: 1.1,
      },
      1.5
    )
    .from(
      ".work-icon-3",
      1,
      {
        y: 200,
        autoAlpha: 0,
        ease: Power3.easeOut,
        delay: 1.2,
      },
      1.6
    )
    .from(
      ".work-icon-4",
      1,
      {
        y: 200,
        autoAlpha: 0,
        ease: Power3.easeOut,
        delay: 1.3,
      },
      1.7
    );
  gsap
    .timeline({
      scrollTrigger: {
        trigger: ".expertise-section",
        start: "top 70%",
        end: "bottom 25%",
        scrub: true,
        // markers: true,
      },
    })
    .staggerFrom(
      ".expertise-wrapper",
      1,
      { y: 200, autoAlpha: 0, ease: Power3.easeOut },
      0.15
    );
  // .staggerFrom(
  //   ".expertise-title-wrapper",
  //   4,
  //   { x: 200, autoAlpha: 0, ease: Power3.easeOut },0.15
  // );

  timeline1
    .from(
      ".intro-wrapper",
      1,
      {
        x: 300,
        autoAlpha: 0,
        ease: Power3.easeOut,
        delay: 1.1,
      },
      1
    )
    .from(
      ".intro-details",
      1,
      {
        x: -300,
        autoAlpha: 0,
        ease: Power3.easeOut,
        delay: 1.2,
      },
      1.1
    )

    .from(
      ".team-1",
      1,
      {
        y: 200,
        autoAlpha: 0,
        ease: Power3.easeOut,
        delay: 1,
      },
      1.4
    )
    .from(
      ".team-2",
      1,
      {
        y: -200,
        autoAlpha: 0,
        ease: Power3.easeOut,
        delay: 1.1,
      },
      1.5
    )
    .from(
      ".team-3",
      1,
      {
        y: 200,
        autoAlpha: 0,
        ease: Power3.easeOut,
        delay: 1.2,
      },
      1.6
    )
    .from(
      ".team-4",
      1,
      {
        y: -200,
        autoAlpha: 0,
        ease: Power3.easeOut,
        delay: 1.3,
      },
      1.7
    );
})();

// toggle readmore btn

$(".learn-more").on("click", function () {
  $(this).parent().find(".moretext").slideToggle();
  if ($(this).find(".myBtn").html() == "Read More") {
    $(this).find(".myBtn").html("Read Less");
  } else {
    $(this).find(".myBtn").html("Read More");
  }
});

(function () {
  // counter section

  gsap.config({
    nullTargetWarn: false,
  });

  gsap.registerPlugin(ScrollTrigger);

  let header = $(".header");

  let timeline1 = gsap.timeline();

  timeline1
    .from("header", 1, {
      y: -200,
      ease: Power3.easeOut,
    })
    .from(".bannerHeader", 1, {
      y: -200,
      ease: Power3.easeOut,
    })
    .from(".bg-cover-img", 1, {
      autoAlpha: 0,
      ease: Power3.easeOut,
    });

  // .from("#hamburger", 1, {
  //   x: 200,
  //   ease: Power3.easeOut,
  // });

  timeline1
    .staggerFrom(
      [
        ".main-banner--left h3",
        ".main-banner--left p",
        ".main-banner--left a",
        ".main-banner--left hr",
        ".main-banner--left .support-msg",
        ".main-banner--left .contact-wrap",
      ],
      1,
      {
        y: 40,
        autoAlpha: 0,
        ease: Power3.easeOut,
        // delay: 0.8,
      },
      0.15
    )
    .from(
      ".main-banner--right",
      1,
      {
        y: 500,
        autoAlpha: 0,
        ease: Power3.easeOut,
        delay: 0.5,
      },
      1.4
    )
    .from(
      ".grid-bg",
      1,
      {
        y: 500,
        autoAlpha: 0,
        ease: Power3.easeOut,
        delay: 1.2,
      },
      1.4
    );

  gsap
    .timeline({
      scrollTrigger: {
        trigger: ".services-wrap",
        start: "top 70%",
        end: "bottom 25%",
        scrub: 1,
      },
    })
    .from(
      ".services-wrap .sc-1",
      1,
      {
        y: 200,
        ease: Power3.easeOut,
        autoAlpha: 0,
      },
      0
    )
    .from(
      ".services-wrap .sc-2",
      1,
      {
        y: 200,
        ease: Power3.easeOut,
        autoAlpha: 0,
      },
      0.2
    )
    .from(
      ".services-wrap .sc-3",
      1,
      {
        y: 200,
        ease: Power3.easeOut,
        autoAlpha: 0,
      },
      0.4
    );
})();

gsap
  .timeline()
  .from(".service-info", 1, {
    x: "-100%",
    ease: Power3.easeInOut,
  })
  .from(
    ".service-image",
    1,
    {
      x: "100%",
      ease: Expo.easeInOut,
      autoAlpha: 0,
    },
    0
  );
